import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'basketball',
  templateUrl: './basketball.component.html',
  styleUrls: ['./basketball.component.css']
})
export class BasketballComponent {

}
