import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'disabled-sport',
  templateUrl: './disabled-sport.component.html',
  styleUrls: ['./disabled-sport.component.css']
})
export class DisabledSportComponent {

}
