import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private user: BehaviorSubject<Observable<firebase.User>> = new BehaviorSubject<Observable<firebase.User>>(null);

    permittedMails = ['steven.lehmann.it@gmail.com', 'larsrk86@gmail.com'];

    user$ = this.user
        .asObservable()
        .pipe(switchMap((user: Observable<firebase.User>) => user));

    constructor(private afAuth: AngularFireAuth) {
        this.user.next(this.afAuth.authState);
    }

    isAuthorized(user: firebase.User) {
        if (!user) {
            return false;
        }
        
        let permitted = false;

        this.permittedMails.forEach(mail => {
            if (user?.email.toLowerCase() === mail.toLowerCase()) {
                permitted = true;
                return;
            }
        })

        return permitted;
    }

    loginViaGoogle(): Observable<auth.UserCredential> {
        return from(this.afAuth.signInWithPopup(new auth.GoogleAuthProvider()));
    }

    logout(): Observable<void> {
        return from(this.afAuth.signOut());
    }
}