import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volleyball-junior',
  templateUrl: './volleyball-junior.component.html',
  styleUrls: ['./volleyball-junior.component.css']
})
export class VolleyballJuniorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
