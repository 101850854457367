import { Component } from '@angular/core';

@Component({
  selector: 'tournement',
  templateUrl: './tournement.component.html',
  styleUrls: ['./tournement.component.scss']
})
export class TournementComponent {

}
