import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-department-image',
  templateUrl: './department-image.component.html',
  styleUrls: ['./department-image.component.scss']
})
export class DepartmentImageComponent {
  @Input() name: string;
  @Input() image: string;
  @Input() link: string;
}
