import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nordic-walking',
  templateUrl: './nordic-walking.component.html',
  styleUrls: ['./nordic-walking.component.css']
})
export class NordicWalkingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
