import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EMPTY, Observable, BehaviorSubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { News } from './../../storage/news-model';
import { StorageService } from './../../storage/storage-service';
import { AuthService } from './../login-service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

  user$: Observable<firebase.User> = this.auth.user$;
  private storage: StorageService;
  news$: BehaviorSubject<News[]> = new BehaviorSubject<News[]>(null);

  constructor(
    private readonly auth: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private _storage: StorageService
  ) {
    this.storage = this._storage;
  }

  ngOnInit() {
    this.loadNews();
  }


  async loadNews() {
    this.storage.getNews().subscribe(
      data => {
        this.news$.next(data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as News
        }))
      }
    );
  }

  createNews() {
    const text = (<HTMLInputElement>document.getElementById('textarea-create')).value;
    (<HTMLInputElement>document.getElementById('textarea-create')).value = '';

    const newEntry = new News();
    newEntry.text = text;

    this.storage.createNews(newEntry);
  }

  updateNews(news: News, i: string) {
    const text = (<HTMLInputElement>document.getElementById('textarea' + i)).value;
    news.text = text;
    this.storage.updateNews(news);
  }

  deleteNews(news: News) {
    this.storage.deleteNews(news);
  }

  hasPermission(user: firebase.User) {
    return this.auth.isAuthorized(user);
  }

  login() {
    this.auth
      .loginViaGoogle()
      .pipe(
        take(1),
        catchError(() => {
          this.snackBar.open(`Fehler beim Einloggen`, 'Close', {
            duration: 4000
          });
          return EMPTY;
        }),
      ).subscribe();
  }

  logout() {
    this.auth
      .logout()
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate([`/start-page`]);
      });
  }

}
