import { AuthService } from './admin-page/login-service';
import { Observable } from 'rxjs';
import { StorageService } from './storage/storage-service';
import { News } from './storage/news-model';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { firestore } from 'firebase';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sv-kreischa';
  menuOpen = false;
  isMobile = false;
  news: News[];
  private storage: StorageService;
  user$: Observable<firebase.User> = this.auth.user$;

  constructor(
    breakpointObserver: BreakpointObserver,
    router: Router,
    private readonly auth: AuthService,
    private _storage: StorageService
  ) {
    this.storage = this._storage;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });

    breakpointObserver
      .observe(['(min-width: 800px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          // desktop
          this.isMobile = false;
          this.menuOpen = true;
        } else {
          // mobile
          this.isMobile = true;
          this.menuOpen = false;
        }
      });
  }

  hasPermission(user: firebase.User) {
    return this.auth.isAuthorized(user);
  }

  ngOnInit() {

    // const testNews = new News();
    // testNews.text = 'Neuer Newsletter ist erschienen! ä?13e?ß';
    // this.storage.createNews(testNews);

    // this.storage.deleteNews({id: 'Xm2PE4hhZBjFrymQBWhb', text: ''});

    this.loadNews();
  }

  async loadNews() {
    this.storage.getNews().subscribe(
      data => {
        this.news = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as News
        })
      }
    );
  }

  isDateOutdated(timestamp: firestore.Timestamp) {
    return !timestamp || timestamp.toDate() > new Date();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
