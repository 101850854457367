import { AdminPageComponent } from './admin-page/admin-page/admin-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BadmintonComponent } from './sites/badminton/badminton.component';
import { BallsportMixComponent } from './sites/ballsport-mix/ballsport-mix.component';
import { BasketballComponent } from './sites/basketball/basketball.component';
import { DisabledSportComponent } from './sites/disabled-sport/disabled-sport.component';
import { DownloadsComponent } from './sites/downloads/downloads.component';
import { GymnasticsDanceComponent } from './sites/gymnastics-dance/gymnastics-dance.component';
import { ImprintComponent } from './sites/imprint/imprint.component';
import { JudoComponent } from './sites/judo/judo.component';
import { LeadingComponent } from './sites/leading/leading.component';
import { ManagementComponent } from './sites/management/management.component';
import { NordicWalkingComponent } from './sites/nordic-walking/nordic-walking.component';
import { StartPageComponent } from './sites/start-page/start-page.component';
import { StatuteComponent } from './sites/statute/statute.component';
import { TournementComponent } from './sites/tournement/tournement.component';
import { VolleyballJuniorComponent } from './sites/volleyball-junior/volleyball-junior.component';
import { VolleyballSeniorComponent } from './sites/volleyball-senior/volleyball-senior.component';

const appRoutes: Routes = [
    { path: 'start-page', component: StartPageComponent },
    { path: 'admin', component: AdminPageComponent },
    { path: 'badminton', component: BadmintonComponent },
    { path: 'basketball', component: BasketballComponent },
    { path: 'volleyball-junior', component: VolleyballJuniorComponent },
    { path: 'volleyball-senior', component: VolleyballSeniorComponent },
    // { path: 'ballsport-mix', component: BallsportMixComponent },
    { path: 'nordic-walking', component: NordicWalkingComponent },
    { path: 'judo', component: JudoComponent },
    { path: 'gymnastics-dance', component: GymnasticsDanceComponent },
    { path: 'disabled-sport', component: DisabledSportComponent },
    { path: 'imprint', component: ImprintComponent },
    { path: 'statute', component: StatuteComponent },
    { path: 'downloads', component: DownloadsComponent },
    { path: 'management', component: ManagementComponent },
    { path: 'tournement', component: TournementComponent },
    { path: 'leading', component: LeadingComponent },
    { path: '', redirectTo: '/start-page', pathMatch: 'full' }
];

@NgModule({
    // TODO
    imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
