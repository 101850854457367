import { Component } from '@angular/core';

@Component({
  selector: 'start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent {

  latitude = 50.943116;
  longitude = 13.757280;

}
