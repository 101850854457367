import { News } from './news-model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private firestore: AngularFirestore;

    constructor(private _firestore: AngularFirestore) {
        this.firestore = this._firestore;
    }

    getNews() {
        return this.firestore.collection<News>('news').snapshotChanges();
    }

    createNews(news: News) {
        this.firestore.collection('news').add({...news});
    }

    deleteNews(news: News) {
        this.firestore.doc('news/' + news.id).delete();
    }

    updateNews(news: News) {
        // delete policy.id;
        this.firestore.doc('news/' + news.id).update(news);
    }
}