import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gymnastics-dance',
  templateUrl: './gymnastics-dance.component.html',
  styleUrls: ['./gymnastics-dance.component.css']
})
export class GymnasticsDanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
