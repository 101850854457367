import { JudoComponent } from './sites/judo/judo.component';
import { LeadingComponent } from './sites/leading/leading.component';
import { ImprintComponent } from './sites/imprint/imprint.component';
import { ManagementComponent } from './sites/management/management.component';
import { DownloadsComponent } from './sites/downloads/downloads.component';
import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapsImageComponent } from './maps-image/maps-image.component';
import { DepartmentImageComponent } from './sites/start-page/department-image/department-image.component';
import { StartPageComponent } from './sites/start-page/start-page.component';
import { StatuteComponent } from './sites/statute/statute.component';
import { TournementComponent } from './sites/tournement/tournement.component';
import { VolleyballJuniorComponent } from './sites/volleyball-junior/volleyball-junior.component';
import { VolleyballSeniorComponent } from './sites/volleyball-senior/volleyball-senior.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BasketballComponent } from './sites/basketball/basketball.component';
import { BadmintonComponent } from './sites/badminton/badminton.component';
import { BallsportMixComponent } from './sites/ballsport-mix/ballsport-mix.component';
import { NordicWalkingComponent } from './sites/nordic-walking/nordic-walking.component';
import { GymnasticsDanceComponent } from './sites/gymnastics-dance/gymnastics-dance.component';
import { DisabledSportComponent } from './sites/disabled-sport/disabled-sport.component';
import { AdminPageComponent } from './admin-page/admin-page/admin-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    BasketballComponent,
    BadmintonComponent,
    VolleyballSeniorComponent,
    VolleyballJuniorComponent,
    BallsportMixComponent,
    NordicWalkingComponent,
    GymnasticsDanceComponent,
    DisabledSportComponent,
    DownloadsComponent,
    StatuteComponent,
    ImprintComponent,
    ManagementComponent,
    TournementComponent,
    DepartmentImageComponent,
    JudoComponent,
    MapsImageComponent,
    LeadingComponent,
    AdminPageComponent
  ],
  imports: [
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule
  ],
  exports: [AngularFireModule, AngularFireAuthModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
